import {
  SecurityLevel, Severity, Significance, VulnerabilityStatus,
} from '../pages/vulnerabilities/Types';
import { Module } from './AccessTypes';
import { EntityType } from './EntityTypes';

export interface Indexable<T> {
  [index: string]: T,
}

export interface IStringDictionary<T> {
  [key: string]: T;
}

export const assetTypesAsString = [
  'unknown',
  'saas',
  'organization',
  'device',
] as const;
export type AssetType = typeof assetTypesAsString[number];

export interface IListOptions {
  page?: number,
  pageSize?: number
}

export interface IPage extends IListOptions {
  page: number,
  pageSize: number
}

export interface IVulnerabilityListOptions extends IListOptions {
  status?: VulnerabilityStatus[],
  severity?: Severity,
  impact?: Significance[],
  id?: number,
  uniqueId?: string,
  summary?: string,
  risk?: string,
  control?: string,
  assignedToId?: string,
  assignedTo?: string,
  probability?: Significance[],
  assets?: string[],
  asset?: string,
  securityLevel?: SecurityLevel,
  sourceModuleIds?: Module[],
  projectToFramework?: string,
  snapshotId?: number,
  topicFriendlyId?:string|undefined
}

export interface IErrorDetails {
  status: number,
  title: string,
  detail: string,
  traceId: string,
  type: string,
  errorDetails: object|undefined,
  soft?: boolean,
}

export enum JobStatus {
    Unknown = 'unknown',
    Pending = 'pending',
    Waiting = 'waiting',
    Active = 'active',
    Failed = 'failed',
    Succeeded = 'succeeded',
    Finalized = 'finalized',
}

export interface IJobType {
  moduleId:number,
  lookupKey:string
}

export interface IJob {
  id:number,
  jobTypeId:string,
  jobType:IJobType,
  status:JobStatus,
  runner:string,
  customerName:string,
  runnerId:string,
  input?:string,
  created:string|Date,
  started?:string|Date,
  ended?:string|Date,
  updated?:string|Date,
  accountExternalId?:string,
}
export interface IJobWithOutput extends IJob {
  output?:string,
  log?:string,
  error?:string
}

export interface IListJob {
  id:number,
  jobTypeId:string,
  jobType:IJobType,
  status:JobStatus,
  runner:string,
  customerName:string,
  runnerId:string,
  created:string|Date,
  started?:string|Date,
  ended?:string|Date,
  updated?:string|Date,
  accountExternalId?:string,
  input?:string,
}

export interface SettingValue {
  key: string,
  module?: Module,
  value: string
}

export interface Setting extends SettingValue {
  id?: string,
  entityId?: string,
  entityType?: EntityType,
}

export interface IHaveTimestamps {
  created: Date,
  updated?: Date
}

export interface IProperty {
  key: string,
  value?: string
}

export interface ISupportProperties {
  properties?: IProperty[]
}
