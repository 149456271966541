import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { copyClipboard } from '../utils/Utils';

export const ClipboardCopy = ({
  children,
  size,
}:{
  children:string|number|undefined|null,
  size?:string|number|undefined
}) => (children
  ? (
    <OverlayTrigger
      overlay={<Tooltip>Copy to clipboard</Tooltip>}
    >
      <Button
        variant="link"
        className="px-1 py-0 text-start"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          copyClipboard(`${children}`, 'Copied');
        }}
      >
        {children}
        <Icon name="copy" className="ms-1" size={size ?? 16} />
      </Button>
    </OverlayTrigger>
  ) : null);
