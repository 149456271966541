import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Badge, Button, Card, OverlayTrigger, Spinner, Stack, Modal, Tooltip, Tab, Tabs,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import axios, { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useStore } from 'zustand';
import { toast } from 'react-toastify';
import {
  getOrFetchFromApi, useApi, useInvalidateQueries,
} from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import {
  IJob, IJobWithOutput, IListJob, JobStatus,
} from '../../types/Types';
import { PageableColumnDefV8 } from '../../common/table/ReactTableV8';
import { TableCellDateFormattedV8 } from '../../common/table/TableHelpersV8';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { ALL_JOB_TYPE_KEYS, Module } from '../../types/AccessTypes';
import { ElementScrollRestoration } from '../../routing/ElementScrollRestoration';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { TableFromPageable } from '../../common/table/TableFromPageable';
import { dateStringFilterFn } from '../../common/table/filters/DateStringFilterV8';
import { useModules } from '../../providers/ModuleProvider';
import { createPageableColumnHelper, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { ValidPageSizes } from '../../common/table/PaginationV8';
import { useNewModalContext } from '../../providers/NewModalProvider';
import { CodeBox } from '../../common/CodeBox';
import { useDownloadFromApi } from '../../common/hooks/useDownloadFromApi';

interface IAdminJobsQuery {
    ids?: number[],
    moduleId?: number[],
    lookupKey?: string,
    status?: JobStatus[],
    customerName?: string,
    accountName?: string,
    runner?: string
}

/**
 * The main admin page for global CRMS admins
 *
 * The page for the "Global Admin" module
 */
export const AdminJobsPage:IComponentWithLoader<{jobs:PagedResult<IJob>|null}, string|null|undefined> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    id?:string|null|undefined,
  ) => (
    {
      jobs: id
        ? await getOrFetchFromApi<PagedResult<IJob>>(
          queryClient,
          'jobs',
          {
            pageSize,
            ids: id ? [id] : undefined,
          },
        )
        : null,
    }
  ),
  Component: () => {
    const [refreshing, setRefreshing] = useState<Record<number, boolean>>({});
    const { hasModuleRole } = useAccount();
    const { customerModules } = useModules();
    const [searchParams] = useSearchParams();
    const jobId = typeof searchParams.get('id') === 'string'
      ? parseInt(searchParams.get('id')!, 10)
      : null;
    const { getModuleNameOrDefault } = useModules();
    const tableId = 'admin-jobs';
    const queryClient = useQueryClient();
    const modal = useNewModalContext();

    const [jobHasData, setJobHasData] = useState<Record<number, boolean>>({});

    const loadJobDetails = useCallback(async (id:number) => {
      try {
        const job = await getOrFetchFromApi<IJobWithOutput>(queryClient, `jobs/${id}?fetchResult=true`);
        jobHasData[job.id] = true;
        setJobHasData({ ...jobHasData });
        return job;
      } catch (err) {
        const axiosErr = err as AxiosError;
        if (axiosErr.response?.status === 404) {
          toast.error('Job output is not available in storage', {
            autoClose: 2500,
          });
        } else {
          toast.error(`Unexpected error while loading job details: ${axiosErr.response?.status} ${axiosErr.message}`);
        }
        jobHasData[id] = false;
        setJobHasData({ ...jobHasData });
        return null;
      }
    }, [jobHasData, queryClient]);

    const {
      pageableQuery: jobsQuery,
      appendQuery,
      setPage,
      reset: resetQuery,
      sorting,
      setSorting,
      isFiltered,
    } = usePagedTableFilter<IAdminJobsQuery>(
      tableId,
      {},
      {
        ids: jobId ? [jobId] : undefined,
      },
    );

    const {
      data: pagedJobs,
      isLoading,
      invalidate: invalidateJobs,
    } = useApi<PagedResult<IJobWithOutput>>(
      'jobs',
      jobsQuery,
    );

    const columnHelper = useMemo(() => createPageableColumnHelper<IListJob>(), []);

    const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');
    const invalidateRisks = useInvalidateQueries('risks');
    const download = useDownloadFromApi();

    const refreshJob = useCallback(
      async (id:number) => {
        refreshing[id] = true;
        // Copy records for useMemo to detect change
        setRefreshing({ ...refreshing });

        const toastId = `job-${id}-refresh`;

        try {
          const { status: statusBefore } = pagedJobs?.items.find((j) => j.id === id) ?? { status: undefined };
          const { data: job } = await axios.get<IJobWithOutput>(`/api/v1/jobs/${id}`);

          if (job.jobType.lookupKey === 'scan'
            && job.status === JobStatus.Finalized
            && statusBefore !== job.status
          ) {
            invalidateRisks();
            invalidateVulnerabilities();
          }

          toast.info(`Job ${job.id} was refreshed`, {
            toastId,
            updateId: toastId,
          });
        } catch (err) {
          const axiosError = err as AxiosError;
          if (axiosError.response?.status === 423) {
            toast.warning('Job finalization in progress. Please wait a few minutes and try again.', {
              toastId,
              updateId: toastId,
            });
          } else {
            toast.warning(`An error occured refreshing job: ${axiosError.response?.status} ${axiosError.response?.statusText}`, {
              toastId,
              updateId: toastId,
            });
          }
        }

        invalidateJobs();
        refreshing[id] = false;
        setRefreshing({ ...refreshing });
      },
      [invalidateJobs, invalidateRisks, invalidateVulnerabilities, pagedJobs?.items, refreshing],
    );

    const getStatusClassName = (status:JobStatus) => {
      switch (status) {
      case JobStatus.Finalized:
        return 'success';
      case JobStatus.Active:
        return 'primary';
      case JobStatus.Succeeded:
        return 'dark';
      case JobStatus.Failed:
        return 'danger';
      default:
        return 'secondary';
      }
    };

    const allModuleOptions = useMemo(() => customerModules.map((m) => m.id), [customerModules]);

    const columnDefs = useMemo(() => {
      const columns = [
        columnHelper.accessor(
          'id',
          {
            header: 'Id',
          },
          {
            filterPropertyName: 'ids',
            sortPropertyName: 'id',
            filterFn: (values: number[] | null) => appendQuery({
              ids: values ?? undefined,
            }),
          },
        ),
        columnHelper.accessor(
          'jobType.moduleId',
          {
            header: 'Module',
            cell: ({ getValue }) => getModuleNameOrDefault(getValue()),
            formatter: getModuleNameOrDefault,
          },
          {
            filterPropertyName: 'moduleId',
            sortPropertyName: 'jobType.moduleId',
            filterFn: (values: number[]) => appendQuery({
              moduleId: values,
            }),
            selectOptions: allModuleOptions,
          },
        ),
        columnHelper.accessor(
          'jobType.lookupKey',
          {
            header: 'Type',
            cell: ({ getValue }) => getValue(),
          },
          {
            filterPropertyName: 'lookupKey',
            sortPropertyName: 'jobType.lookupKey',
            filterFn: (values: string[]) => appendQuery({
              lookupKey: values.length ? values[0] : undefined,
            }),
            selectOptions: ALL_JOB_TYPE_KEYS,
          },
        ),
        columnHelper.accessor(
          'status',
          {
            header: 'Status',
            cell: ({ getValue }) => {
              const value = getValue();
              return <Badge bg={getStatusClassName(value)}>{value}</Badge>;
            },
            enableColumnFilter: false,
            formatter: (value: JobStatus) => String(value),
          },
          {
            filterPropertyName: 'status',
            filterFn: (values: JobStatus[]) => appendQuery({
              status: values,
            }),
            supportMultiSelect: true,
            selectOptions: [
              JobStatus.Active,
              JobStatus.Failed,
              JobStatus.Finalized,
              JobStatus.Pending,
              JobStatus.Succeeded,
              JobStatus.Unknown,
              JobStatus.Waiting,
            ],
          },
        ),
        columnHelper.accessor(
          'customerName',
          {
            header: 'Customer',
          },
          {
            filterPropertyName: 'customerName',
            sortPropertyName: 'customer.name',
            filterFn: (values: string[]) => appendQuery({
              customerName: values.length ? values[0] : undefined,
            }),
          },
        ),
        columnHelper.accessor(
          'accountExternalId',
          {
            header: 'Account',
            defaultHidden: true,
          },
          {
            filterPropertyName: 'accountName',
            sortPropertyName: 'account.externalId',
            filterFn: (values: string[]) => appendQuery({
              accountName: values.length ? values[0] : undefined,
            }),
          },
        ),
        columnHelper.accessor('created', {
          header: 'Created',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue(), { timeStyle: 'medium' }),
          enableColumnFilter: false,
          enableSorting: false,
        }),
        columnHelper.accessor('started', {
          header: 'Started',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue(), { timeStyle: 'medium' }),
          filterFn: dateStringFilterFn,
          defaultHidden: true,
          enableColumnFilter: false,
          enableSorting: false,
        }),
        columnHelper.accessor('ended', {
          header: 'Finished',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue(), { timeStyle: 'medium' }),
          enableColumnFilter: false,
          enableSorting: false,
        }),
        columnHelper.accessor('updated', {
          header: 'Last updated',
          cell: ({ getValue }) => TableCellDateFormattedV8(getValue(), { timeStyle: 'medium' }),
          defaultHidden: true,
          enableSorting: false,
          enableColumnFilter: false,
        }),
        columnHelper.accessor(
          'runner',
          {
            header: 'Runner',
            cell: ({ getValue }) => (
              <span className="font-monospace small">{getValue()}</span>
            ),
            defaultHidden: true,
          },
          {
            filterPropertyName: 'runner',
            filterFn: (values: string[]) => appendQuery({
              runner: values.length ? values[0] : undefined,
            }),
          },
        ),
        {
          ...columnHelper.accessor('input', {
            header: 'Input',
            cell: ({ getValue, row }) => {
              const value = getValue();

              return value ? (
                <OverlayTrigger
                  placement="auto"
                  overlay={(
                    <Tooltip>
                      <div>Click to show</div>
                    </Tooltip>
                  )}
                >
                  <Button
                    className="float-end"
                    variant="text"
                    size="sm"
                    onClick={async () => {
                      modal.pushModal({
                        title: `Job ${row.original.id} input`,
                        size: 'lg',
                        nofocus: true,
                        ModalBodyAndFooter: ({ close }) => (
                          <>
                            <Modal.Body>
                              <CodeBox text={value} />
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                onClick={() => {
                                  close(true);
                                }}
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </>
                        ),
                      });
                    }}
                  >
                    <Icon name="copy" />
                  </Button>
                </OverlayTrigger>
              ) : null;
            },
          }),
          enableColumnFilter: false,
        },
        {
          ...columnHelper.display({
            header: 'Output',
            cell: ({ row }) => {
              const jobOutput = jobHasData[row.original.id];
              if (jobOutput === false) return null;

              if (
                row.original.status === JobStatus.Finalized
                || row.original.status === JobStatus.Failed
              ) {
                return (
                  <OverlayTrigger
                    placement="auto"
                    overlay={(
                      <Tooltip>
                        <div>Click to show</div>
                      </Tooltip>
                    )}
                  >
                    <Button
                      className="float-end"
                      variant="text"
                      size="sm"
                      onClick={async () => {
                        const job = await loadJobDetails(row.original.id);

                        if (!job) return;

                        modal.pushModal({
                          title: `Job ${job.id} output`,
                          size: 'xl',
                          nofocus: true,
                          ModalBodyAndFooter: ({ close }) => (
                            <>
                              <Modal.Body>
                                <Tabs className="secondary">
                                  {job.error ? (
                                    <Tab title="Error" eventKey="error">
                                      <pre className="pre-wrap mt-3">
                                        {job.error}
                                      </pre>
                                    </Tab>
                                  ) : null}
                                  {job.output ? (
                                    <Tab title="Output" eventKey="output">
                                      <pre className="pre-wrap mt-3">
                                        {job.output}
                                      </pre>
                                    </Tab>
                                  ) : null}
                                  {job.log ? (
                                    <Tab title="Log" eventKey="log">
                                      <pre className="pre-wrap mt-3">
                                        {job.log}
                                      </pre>
                                    </Tab>
                                  ) : null}
                                </Tabs>
                              </Modal.Body>
                              <Modal.Footer>
                                <Stack direction="horizontal" gap={3}>
                                  <Button
                                    variant="secondary"
                                    onClick={() => download(`jobs/${job.id}/downloadOutput`)}
                                  >
                                    Output
                                    <Icon
                                      name="download"
                                      className="ms-2"
                                      size={16}
                                    />
                                  </Button>
                                  <Button
                                    variant="secondary"
                                    onClick={() => download(`jobs/${job.id}/downloadLog`)}
                                  >
                                    Log
                                    <Icon
                                      name="download"
                                      className="ms-2"
                                      size={16}
                                    />
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      close(true);
                                    }}
                                  >
                                    Close
                                  </Button>
                                </Stack>
                              </Modal.Footer>
                            </>
                          ),
                        });
                      }}
                    >
                      <Icon name="copy" />
                    </Button>
                  </OverlayTrigger>
                );
              }

              return (
                <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip>Click to refresh</Tooltip>}
                >
                  <Button
                    className="float-end overflow-hidden"
                    variant="primary"
                    size="sm"
                    disabled={refreshing[row.original.id]}
                    onClick={async () => refreshJob(row.original.id)}
                  >
                    {refreshing[row.original.id] ? (
                      <div
                        style={{ width: 24, height: 24 }}
                        className="overflow-hidden"
                      >
                        <Spinner size="sm" className="mt-1" />
                      </div>
                    ) : (
                      <Icon name="refresh-cw" />
                    )}
                  </Button>
                </OverlayTrigger>
              );
            },
            enableColumnFilter: false,
            enableSorting: false,
          }),
        },
      ];
      return columns;
    }, [
      columnHelper,
      getModuleNameOrDefault,
      allModuleOptions,
      appendQuery,
      modal,
      jobHasData,
      refreshing,
      loadJobDetails,
      download,
      refreshJob,
    ]);

    const { store: tableStore } = useTableStoreV8(
      'adminJobs',
      {
        visibilityState: columnsToVisibilityState(columnDefs),
        pageSize: jobsQuery.pageSize as ValidPageSizes,
      },
    );

    const tableState = useStore(tableStore);

    return !hasModuleRole(Module.admin, 'read') ? null : (
      <>
        <Card className="fill-content">
          <Card.Body className="overflow-auto" id="jobs-card">
            { pagedJobs
              ? (
                <TableFromPageable
                  id={tableId}
                  pagedResult={pagedJobs}
                  pageSize={jobsQuery.pageSize}
                  setPage={setPage}
                  resetFilters={resetQuery}
                  isFiltered={isFiltered}
                  filterValues={jobsQuery}
                  sorting={sorting}
                  setSorting={setSorting}
                  isLoading={isLoading}
                  state={tableState}
                  columnDefs={columnDefs as PageableColumnDefV8<IJob, unknown>[]}
                  refresh={invalidateJobs}
                />
              )
              : <Spinner animation="border" className="mt-3" />}
          </Card.Body>
        </Card>
        <ElementScrollRestoration targetId="jobs-card" />
      </>
    );
  },
};

export default AdminJobsPage;
