import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import {
  Card, Col, Row,
  Spinner,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import { IVulnerabilityListOptions } from '../../types/Types';
import {
  ISeverityComponents, IVulnerability, SecurityLevel, Significance, VulnerabilityStatus,
} from './Types';
import VulnerabilityFilter, { IVulnerabilityFilter, QueryUtil } from './VulnerabilityFilter';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { VulnerabilitiesTable } from './VulnerabilitiesTable';
import { Module } from '../../types/AccessTypes';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { getOrFetchFromApi, useApi, useApiLoaderData } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { getFilterUpdateForSelectedSeverityComponent, isSeverityComponentSelectedInOptions } from '../riskregistry/Utils';
import { TableStateV8 } from '../../common/table/TableStoreV8';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { ValidPageSizes } from '../../common/table/PaginationV8';
import { ProbabilityAndImpactMatrix } from '../riskregistry/RiskMatrix';

interface ILoaderData {
  options: IVulnerabilityListOptions|undefined,
  matrixSummary: Record<Significance, Record<Significance, number>>
}

export const VulnerabilitiesPage:IComponentWithLoader<
  ILoaderData,
  { filters:IVulnerabilityFilter, options:IVulnerabilityListOptions }
> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:{ filters:IVulnerabilityFilter, options:IVulnerabilityListOptions }|undefined,
  ) => {
    const initialOptions:IVulnerabilityListOptions&{sortBy?: string|undefined} = {
      ...args?.options ?? {},
      pageSize,
      page: args?.options.page ?? 1,
      status: [VulnerabilityStatus.Open],
    };

    return {
      matrixSummary: await getOrFetchFromApi<Record<Significance, Record<Significance, number>>>(
        queryClient,
        'vulnerabilities/severityMatrixSummary',
      ),
      options: initialOptions,
    };
  },
  Component: () => {
    const [tableState, setTableState] = useState<TableStateV8>();
    const { getCustomerSetting } = useAccount();

    const [searchParams, setSearchParams] = useSearchParams();

    const {
      options,
    } = useLoaderData() as Awaited<ILoaderData>;

    const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
      'vulnerabilities-page',
      {},
      options ?? {},
      [
        { property: 'severity', direction: Direction.desc },
      ],
    );

    const { pageableQuery } = pagedTableFilter;

    const {
      data: pagedVulnerabilities,
      isLoading: vulnerabilitiesLoading,
    } = useApi<PagedResult<IVulnerability>>(
      'vulnerabilities',
      pageableQuery,
    );

    const { data: matrixSummary } = useApiLoaderData<Record<Significance, Record<Significance, number>>, ILoaderData>(
      'vulnerabilities/severityMatrixSummary',
      (loaderData) => loaderData.matrixSummary,
    );

    const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);

    // const { store: vulnerabilitiesTableStore } = useTableStoreV8(vulnerabilitiesStoreId);
    // useStore(vulnerabilitiesTableStore);
    const { filters: tableFilters, getFilterValues, addOrUpdateFilterValues } = tableState ?? {};

    const queryUtil = new QueryUtil<IVulnerabilityFilter>(
      customerSecurityLevel,
      (query) => {
        if (tableFilters && addOrUpdateFilterValues) {
          const filters = query.getFilters();
          const filterKeys = tableFilters.map((f) => f.id);
          const updatedFilters = Object.keys(filters)
            .filter((key) => filterKeys.includes(key))
            .map((key) => ({
              id: key,
              value: [filters[key]],
            }));
          addOrUpdateFilterValues(updatedFilters);
        }
      },
      [searchParams, setSearchParams],
    );

    const getIsFilteredClassName = useCallback((item:ISeverityComponents) => (
      isSeverityComponentSelectedInOptions(item, pageableQuery) ? 'highlight' : ''
    ), [pageableQuery]);

    const maxMatrixCount = useMemo(() => (
      Math.max.apply(null, Object.values(matrixSummary).flatMap((r) => Object.values(r)))
    ), [matrixSummary]);

    return (
      <>
        <Row>
          <Col md={12}>
            <VulnerabilityFilter
              customerSecurityLevel={customerSecurityLevel}
              queryUtil={queryUtil}
              /* snapshots={snapshots}
              selectedSnapshot={selectedSnapshot} */
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                Vulnerabilities by severity
              </Card.Header>
              <Card.Body>
                <ProbabilityAndImpactMatrix
                  getCount={(item) => (
                    (matrixSummary[item.probability] ? matrixSummary[item.probability][item.impact] : 0) ?? 0
                  )}
                  maxCount={maxMatrixCount}
                  cellClassName={getIsFilteredClassName}
                  onClick={(item) => {
                    const newOptions = { ...pageableQuery };
                    if (newOptions.impact?.includes(item.impact)
                      && newOptions.probability?.includes(item.probability)
                    ) {
                      newOptions.impact = newOptions.impact.filter((i) => i !== item.impact);
                      newOptions.probability = newOptions.probability.filter((p) => p !== item.probability);
                    } else {
                      newOptions.impact = [item.impact];
                      newOptions.probability = [item.probability];
                    }
                    newOptions.severity = undefined;
                    pagedTableFilter.appendQuery(newOptions);

                    if (addOrUpdateFilterValues && getFilterValues) {
                      addOrUpdateFilterValues(
                        getFilterUpdateForSelectedSeverityComponent({
                          ...item,
                          getFilterValues,
                        }),
                      );
                    }
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <Card.Header>
                Vulnerabilities
              </Card.Header>
              <Card.Body className="overflow-auto">
                <Row>
                  <Col md={12}>
                    { !pagedVulnerabilities || vulnerabilitiesLoading
                      ? <Spinner animation="border" />
                      : (
                        <VulnerabilitiesTable
                          isPaged
                          id="vulnerabilities-page"
                          emptyText={pagedTableFilter ? undefined : 'There are no vulnerabilities registered for your company'}
                          onInitialized={setTableState}
                          pagedResult={pagedVulnerabilities}
                          queryOptions={options}
                          pagedTableFilter={pagedTableFilter}
                          isLoading={vulnerabilitiesLoading}
                        />
                      )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  },
};

export default VulnerabilitiesPage;
