import React, { useMemo, useState } from 'react';
import {
  Button, Modal, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { createColumnHelper } from '@tanstack/react-table';
import { useStore } from 'zustand';
import { EntityType, IHaveEntityId } from '../types/EntityTypes';
import { useApi } from '../query/GenericQuery';
import { useEntityTypeAsText } from '../utils/TranslationUtils';
import { PagedResult } from '../types/PagedResult';
import { TableCellDateFormattedV8, TableColumnDefV8 } from '../common/table';
import { columnsToVisibilityState, useTableStoreV8 } from '../common/table/TableStoreV8';
import { TableFromArray } from '../common/table/TableFromArray';
import { usePageCache } from '../common/table/PaginationV8';

interface IProps extends IHaveEntityId {
  show: boolean,
  handleClose: () => void,
  filterByObjectTypes?: string[]|string|undefined
}

interface IChangelog {
  timestamp:Date,
  changeType:string,
  objectTypeName:string,
  key?:string,
  valueFrom?:string,
  valueTo?:string,
  upn?:string,
  appId?:string,
  tenantId?:string,
  entityType?:string
}

export const EntityChangelogModal = (props: IProps) => {
  const {
    show, handleClose, entityId, entityType, filterByObjectTypes,
  } = props;

  const { pagination } = usePageCache('entity-changelog');

  const { data: changelog } = useApi<PagedResult<IChangelog>>(
    show && `changelog/${encodeURIComponent(entityType)}/${encodeURIComponent(entityId)}`,
    {
      entityType,
      pageSize: pagination.pageSize,
    },
  );

  const columnHelper = createColumnHelper<IChangelog>();

  const columns = React.useMemo<TableColumnDefV8<IChangelog, unknown>[]>(() => (
    [
      columnHelper.accessor('timestamp', {
        header: 'Timestamp',
        cell: ({ getValue }) => TableCellDateFormattedV8(getValue()),
      }),
      columnHelper.accessor('changeType', {
        header: 'Change type',
      }),
      columnHelper.accessor('objectTypeName', {
        header: 'Type',
      }),
      columnHelper.accessor('key', {
        header: 'Key',
      }),
      columnHelper.accessor('valueFrom', {
        header: 'From',
      }),
      columnHelper.accessor('valueTo', {
        header: 'To',
      }),
      columnHelper.accessor('upn', {
        header: 'Account',
      }),
    ] as TableColumnDefV8<IChangelog, unknown>[]
  ), [columnHelper]);

  const entityTypeAsText = useEntityTypeAsText();

  const items = useMemo(() => {
    if (!filterByObjectTypes) {
      return changelog?.items;
    }

    const filter = typeof filterByObjectTypes === 'string'
      ? [filterByObjectTypes]
      : filterByObjectTypes;

    return changelog?.items.filter((item) => item.entityType && filter.includes(item.entityType));
  }, [changelog, filterByObjectTypes]);

  const { store } = useTableStoreV8(
    `entityChangelog_${entityType}_${entityId}`,
    {
      visibilityState: columnsToVisibilityState(columns),
    },
  );

  const state = useStore(store);

  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Changelog for
          {' '}
          {entityTypeAsText(entityType)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { !items ? <Spinner animation="border" /> : (
          <TableFromArray
            state={state}
            columnDefs={columns}
            data={items}
            disableColumnSelect
            disablePagination
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

interface IButtonProps {
  entityId:string|number,
  entityType:EntityType,
  filterByObjectTypes?: string[]|string|undefined,
}

export const EntityChangelogButton = (props:IButtonProps) => {
  const { entityId, entityType, filterByObjectTypes } = props;
  const [showChangelog, setShowChangelog] = useState(false);

  const openChangelog = () => {
    setShowChangelog(true);
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip>
            Show changelog
          </Tooltip>
        )}
      >
        <Button variant="link" onClick={openChangelog} className="px-0">
          <Icon name="clock" size="18" />
        </Button>
      </OverlayTrigger>
      <EntityChangelogModal
        show={showChangelog}
        handleClose={() => setShowChangelog(false)}
        entityId={entityId}
        entityType={entityType}
        filterByObjectTypes={filterByObjectTypes}
      />
    </>
  );
};
