/* eslint-disable no-plusplus */
import React, {
  useMemo, useState,
} from 'react';
import {
  Button, Card, Col, Row, Spinner,
} from 'react-bootstrap';
import { ColumnFiltersState, VisibilityState } from '@tanstack/react-table';
import { useStore } from 'zustand';
import { QueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import RiskOverviewChart from './RiskOverviewChart';
import { RiskSummaryMatrix } from './RiskMatrix';
import { RiskTable } from './RiskTable';
import { IRisk } from '../../types/RiskTypes';
import RiskForm from './RiskForm';
import { FormModal } from '../../common/modal';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { useTableStoreV8 } from '../../common/table/TableStoreV8';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { Severity, Significance } from '../vulnerabilities/Types';
import { getFilterUpdateForSelectedSeverityComponent, isSeverityComponentFiltered } from './Utils';
import { convertSortingToSortBy, Direction } from '../../common/table/PagedResultFilter';

const getActiveSeverityFromSearchParamsOrFilters = (filters:ColumnFiltersState) => {
  const activeSeverityFilter = filters.find((f) => f.id === 'severity');
  if (activeSeverityFilter?.value) {
    const values = activeSeverityFilter.value as string[];
    if (values.length === 1) {
      return values[0] as Severity;
    }
  }
  return undefined;
};

export const RiskPage:IComponentWithLoader<PagedResult<IRisk>, undefined> = {
  loader: async (queryClient:QueryClient) => (
    getOrFetchFromApi<PagedResult<IRisk>>(
      queryClient,
      'risks',
    )
  ),
  Component: () => {
    const { hasModuleRole, hasAnyModuleRole } = useAccount();
    const [searchParams] = useSearchParams();

    const [selectedRisk, setSelectedRisk] = useState<IRisk>();
    const [show, setShow] = useState(false);

    const handleOpen = (risk?: IRisk) => {
      setSelectedRisk(risk);
      setShow(true);
    };
    const handleClose = () => setShow(false);

    const defaultColumnVisibility:VisibilityState = {
      owner: false,
      response: false,
      probability: false,
      impact: false,
      created: false,
      updated: false,
      status: false,
    };

    const initialFilters = useMemo(() => {
      let mInitialFilters:ColumnFiltersState = [];
      if (searchParams.has('severity')) {
        mInitialFilters = [
          { id: 'severity', value: [searchParams.get('severity') as Severity] },
          { id: 'probability', value: null },
          { id: 'impact', value: null },
        ];
      } else if (searchParams.has('probability') && searchParams.has('impact')) {
        const probability = searchParams.get('probability') as Significance;
        const impact = searchParams.get('impact') as Significance;
        if (probability && impact) {
          mInitialFilters = [
            { id: 'probability', value: [probability] },
            { id: 'impact', value: [impact] },
            { id: 'severity', value: null },
          ];
        }
      }
      return mInitialFilters;
    }, [searchParams]);

    const { store: tableStore } = useTableStoreV8(
      'risk',
      {
        visibilityState: defaultColumnVisibility,
        initialFilters,
        initialSorting: [
          { property: 'severity', direction: Direction.desc },
        ],
      },
    );

    const tableState = useStore(tableStore);

    const { data: risks } = useApiLoaderData<PagedResult<IRisk>, PagedResult<IRisk>>(
      'risks',
      (loaderData) => loaderData,
      {
        sortBy: convertSortingToSortBy(tableState.sorting),
      },
    );

    const {
      addOrUpdateFilterValues,
      getFilterValues,
      filters,
    } = tableState;

    const onSeverityFilterChanged = (selectedSeverities:Severity[]) => {
      addOrUpdateFilterValues([
        { id: 'severity', value: selectedSeverities.length ? [selectedSeverities[0]] : null },
        { id: 'probability', value: null },
        { id: 'impact', value: null },
      ]);
    };

    return (
      <div>
        <Row>
          <Col sm={6}>
            <Card className="flex-fill w-100 fixed-height-card">
              <Card.Header>
                <Card.Title className="mb-0">
                  Risk overview
                </Card.Title>
              </Card.Header>
              <Card.Body className="py-3">
                { !risks
                  ? <Spinner animation="border" />
                  : (
                    <RiskOverviewChart
                      risks={risks.items}
                      onClick={onSeverityFilterChanged}
                      activeSeverity={
                        getActiveSeverityFromSearchParamsOrFilters(filters)
                      }
                    />
                  )}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={6}>
            <Card className="flex-fill w-100 fixed-height-card">
              <Card.Header>
                <Card.Title className="mb-0">Risk matrix</Card.Title>
              </Card.Header>
              <Card.Body className="py-3 overflow-auto">
                { risks
                  ? (
                    <RiskSummaryMatrix
                      risks={risks.items}
                      onClick={(item) => (
                        addOrUpdateFilterValues(
                          getFilterUpdateForSelectedSeverityComponent({ ...item, getFilterValues }),
                        )
                      )}
                      cellClassName={(item) => (isSeverityComponentFiltered({ ...item, getFilterValues }) ? 'highlight' : '')}
                    />
                  )
                  : <Spinner animation="border" />}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card flex-fill w-100">
              <Card.Header>
                <Card.Title className="mb-0">
                  Identified risks
                </Card.Title>
              </Card.Header>
              <Card.Body className="overflow-auto">
                <>
                  <Row>
                    <Col md={12} className="mb-3">
                      <RiskTable
                        risks={risks.items}
                        openForm={handleOpen}
                        state={tableState}
                        defaultColumnVisibility={defaultColumnVisibility}
                      />
                    </Col>
                  </Row>
                  { hasModuleRole(Module.riskUnmanaged, 'readWrite') ? (
                    <Row>
                      <Col md={12}>
                        <Button variant="primary" onClick={() => handleOpen(undefined)}>Add risk</Button>
                      </Col>
                    </Row>
                  ) : null}
                </>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        { hasAnyModuleRole(Module.riskUnmanaged)
          ? (
            <FormModal
              itemType={selectedRisk ? `risk ${selectedRisk.riskId}` : 'risk'}
              isAdd={!selectedRisk}
              show={show}
              handleClose={handleClose}
              Form={<RiskForm risk={selectedRisk} handleClose={handleClose} />}
            />
          )
          : null }
      </div>
    );
  },
};

export default RiskPage;
