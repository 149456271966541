import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../query/GenericQuery';
import { VulnerabilitiesTable } from './VulnerabilitiesTable';
import { PagedResult } from '../../types/PagedResult';
import { IVulnerability, Severity, VulnerabilityStatus } from './Types';
import ROUTES from '../../routing/Routes';
import { Direction, ISorting, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IVulnerabilityListOptions } from '../../types/Types';

export const TopVulnerabilitiesTable = ({
  severity,
  sorting,
}:{
  severity?:Severity,
  sorting?:ISorting[],
}) => {
  const navigate = useNavigate();

  const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
    'top-vulnerabilities',
    {},
    {
      status: [VulnerabilityStatus.Open],
    },
    [
      { property: 'severity', direction: Direction.desc },
    ],
  );

  const { data: pagedVulnerabilities } = useApi<PagedResult<IVulnerability>>(
    'vulnerabilities',
    {
      ...pagedTableFilter.pageableQuery,
      status: 'open',
      severity: severity ?? 'high',
    },
  );

  return pagedVulnerabilities
    ? (
      <VulnerabilitiesTable
        isPaged
        pagedTableFilter={pagedTableFilter}
        id="top-vulnerabilities"
        emptyText={(
          <>
            <p>
              There are no critical vulnerabilities to worry about!
            </p>
            <Button
              onClick={() => navigate(ROUTES.vulnerabilities.uri)}
            >
              Show all vulnerabilities
            </Button>
          </>
        )}
        pagedResult={pagedVulnerabilities}
        hide={{
          actions: true,
          risk: true,
          updated: true,
          status: true,
          uniqueId: true,
        }}
        disableFilters
        skipFilterFromSearchParamInitialization
        sorting={sorting ?? [
          { property: 'asset', direction: Direction.desc },
        ]}
      />
    )
    : <Spinner animation="border" />;
};
